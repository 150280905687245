import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICreatorTabs {
  currentTab: 'My Badges' | 'Leaderboards' | 'About' | 'All Badges';
  currentFilter: { [key in string]: string | boolean };
  scrollPosition: string;
}

const initialState: ICreatorTabs = {
  currentTab: 'My Badges',
  currentFilter: { order: '-1', rarity: false },
  scrollPosition: '',
};

const badgeSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    setCurrentTab: (state: ICreatorTabs, action: PayloadAction<any>) => {
      const tab = action.payload;
      state.currentTab = tab;
    },
    setCurrentFilter: (state: ICreatorTabs, action: PayloadAction<any>) => {
      const filter = action.payload;
      state.currentFilter = filter;
    },
    setScrollPosition: (state: ICreatorTabs, action: PayloadAction<string>) => {
      const position = action.payload;
      state.scrollPosition = position;
    },
  },
});

export const { setCurrentTab, setCurrentFilter, setScrollPosition } = badgeSlice.actions;
export default badgeSlice.reducer;
