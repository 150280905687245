import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../config/base-query';
import {
  IConfirmResetResponse,
  ILoginPayload,
  IResetPasswordPayload,
  ISignUpPayload,
} from 'app/auth/auth.typings';
import { IAuthOptions } from '@lib/cookie.service';

export const authApi = createApi({
  reducerPath: 'auth-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    signUp: builder.mutation<unknown, ISignUpPayload>({
      query(payload) {
        return {
          url: `/users`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    login: builder.mutation<IAuthOptions, ILoginPayload>({
      query(payload) {
        return {
          url: `/vault-auth`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    forgotPassword: builder.mutation<void, string>({
      query(email) {
        return {
          url: '/users/change-password',
          method: 'POST',
          body: {
            email,
          },
        };
      },
    }),
    confirmResetPassword: builder.mutation<IConfirmResetResponse, string>({
      query(token) {
        return {
          url: `/users/user-confirmation/${token}`,
          method: 'GET',
        };
      },
    }),
    resetPassword: builder.mutation<void, IResetPasswordPayload>({
      query({ token, password }) {
        return {
          url: '/users/password-reset',
          method: 'PUT',
          body: {
            new_password: password,
            token,
          },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useConfirmResetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
