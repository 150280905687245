import { monetizationApiService } from '@monetization/service';
import { dataQuery } from './leaderboard-api';

export const monetizationApi = monetizationApiService(dataQuery);
export const {
  useGetCreatorProductsQuery,
  useCreateUserSubscriptionMutation,
  useCancelUserSubscriptionMutation,
  useDeleteProductTierMutation,
  useGetUserSubscriptionsQuery,
  useCreateDonationMutation,
  useGetSentDonationsQuery,
} = monetizationApi;
