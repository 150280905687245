import { createApi } from '@reduxjs/toolkit/query/react';
import { IEngagementScoreResponse, IFanEngagementQueryParams } from '@ui/components';
import { QUERIES } from '@constants/query.constants';
import { dataQuery } from './leaderboard-api';

export const engagementApi = createApi({
  reducerPath: 'engagement-api',
  baseQuery: dataQuery,
  tagTypes: [QUERIES.engagementScore],
  endpoints: (builder) => ({
    getEngagementScore: builder.query<IEngagementScoreResponse, IFanEngagementQueryParams>({
      query: ({ fan_id, creator_id }) => {
        return {
          url: `/public/v1/get-engagement-scores/${fan_id}`,
          params: {
            creator_id,
            initial_date: '2022-01-21',
            final_date: '2040-01-01',
          },
        };
      },
      transformResponse: (data: IEngagementScoreResponse) => {
        return data;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.creator_id !== previousArg?.creator_id;
      },
      providesTags: [QUERIES.engagementScore],
    }),
  }),
});

export const { useGetEngagementScoreQuery } = engagementApi;
