import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChallengeState {
  isModalOpen: boolean;
  currentChallengeId: string | null;
  currentBadgeId: string | null;
  challengeMessage: string;
}

const initialState: ChallengeState = {
  isModalOpen: false,
  currentChallengeId: null,
  currentBadgeId: null,
  challengeMessage: '',
};

const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    setChallengeCompleted: (
      state: ChallengeState,
      action: PayloadAction<{
        challengeId: string;
        challengeName: string;
        // creatorId: string;
        badgeId: string;
      }>,
    ) => {
      state.isModalOpen = true;
      state.challengeMessage = `${action.payload.challengeName}`;
      state.currentBadgeId = `${action.payload.badgeId}`;
      state.currentChallengeId = `${action.payload.challengeId}`;
    },
    closeModal: (state: ChallengeState) => {
      state.isModalOpen = false;
    },
    resetChallengeState: (state: ChallengeState) => {
      state.isModalOpen = false;
      state.challengeMessage = '';
    },
  },
});

export const { setChallengeCompleted, closeModal, resetChallengeState } = challengeSlice.actions;
export default challengeSlice.reducer;
