import { challengeApiService } from '@challenge/service';
import { dataQuery } from './leaderboard-api';

export const challengeApi = challengeApiService(dataQuery);
export const {
  useGetChallengesQuery,
  useGetChallengeByIdQuery,
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
  useDeleteChallengeMutation,
  useGetChallengeBadgeRequirementsByIdQuery,
} = challengeApi;
