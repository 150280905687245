'use client';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { userApi } from './api/user-api';
import { badgesApi } from './api/badges-api';
import { claimApi } from './api/claim-api';
import { authApi } from './api/auth-api';
import { messagesApi } from './api/messages-api';
import { creatorsApi } from './api/creators-api';
import { userReducer } from './features/user';
import badgesReducer from './badges/badges';
import challengeReducer from './challenge/challenge';
import { leaderboardApi } from './api/leaderboard-api';
import { fanConversationsApi } from './api/conversations-api';
import { messagesApiV2 } from './api/messages-api-v2';
import { autocompleteApi } from './api/autocomplete-api';
import { notificationApi } from './api/notification-api';
import { engagementApi } from './api/engagement-api';
import { challengeApi } from './api/challenge-api';
import { monetizationApi } from './api/monetization-api';

export const store = configureStore({
  reducer: {
    user: userReducer,
    badges: badgesReducer,
    challenge: challengeReducer,
    [userApi.reducerPath]: userApi.reducer,
    [badgesApi.reducerPath]: badgesApi.reducer,
    [claimApi.reducerPath]: claimApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [leaderboardApi.reducerPath]: leaderboardApi.reducer,
    [creatorsApi.reducerPath]: creatorsApi.reducer,
    [fanConversationsApi.reducerPath]: fanConversationsApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [messagesApiV2.reducerPath]: messagesApiV2.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [engagementApi.reducerPath]: engagementApi.reducer,
    [challengeApi.reducerPath]: challengeApi.reducer,
    [monetizationApi.reducerPath]: monetizationApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      userApi.middleware,
      badgesApi.middleware,
      claimApi.middleware,
      authApi.middleware,
      messagesApi.middleware,
      leaderboardApi.middleware,
      creatorsApi.middleware,
      fanConversationsApi.middleware,
      autocompleteApi.middleware,
      messagesApiV2.middleware,
      notificationApi.middleware,
      engagementApi.middleware,
      challengeApi.middleware,
      monetizationApi.middleware,
    ]);
  },
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
