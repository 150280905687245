import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { authQuery } from '../config/base-query';
import { cookieService } from '@lib/cookie.service';
import { QUERIES } from '@constants/query.constants';

import {
  IClaimPayload,
  ITransferPayload,
  ICheckFanAchievementParams,
  ICheckFanAchievementResponse,
} from 'app/(badge)/claim.typings';
import { IBadgeDisplay, IGetBadgesResponse } from 'app/(app)/creator.typings';
import { formatBadgeResponse } from 'app/(app)/creator.utils';

export const badgesAdapter = createEntityAdapter({
  selectId: (item: IBadgeDisplay) => {
    return item.id;
  },
});

export const allBadgesAdapter = createEntityAdapter({
  selectId: (item: IBadgeDisplay) => {
    return item.id;
  },
});

export const badgesSelector = badgesAdapter.getSelectors();
export const allBadgesSelector = badgesAdapter.getSelectors();

export const badgesApi = createApi({
  reducerPath: 'badges-api',
  baseQuery: authQuery,
  tagTypes: [QUERIES.badges, QUERIES.creators],
  endpoints: (builder) => ({
    getBadgesInfinite: builder.query({
      query({ creatorId, fanId, page, pageSize }) {
        const userId = cookieService.getUserId();

        return {
          url: `/badges/badges-by-company/${
            fanId || userId
          }?author=${creatorId}&page=${page}&pageSize=${pageSize}`,
        };
      },
      transformResponse({ byCompany, badge_pages }: IGetBadgesResponse) {
        const badges = badgesAdapter.addMany(
          badgesAdapter.getInitialState(),
          byCompany.map(formatBadgeResponse),
        );

        return {
          pages: badge_pages.pages,
          badgeAmount: badge_pages.total_values,
          badges,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSamePage = currentArg.page === previousArg?.page;
        const isSameFan = currentArg.fanId === previousArg?.fanId;

        return !isSameCreator || !isSamePage || !isSameFan;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}-${queryArgs.fanId}`;
      },
      merge(currentState, incomingState) {
        badgesAdapter.addMany(currentState.badges, badgesSelector.selectAll(incomingState.badges));
      },
    }),
    getBadgesByAuthor: builder.query({
      query({ creatorId, page, pageSize, order = '-1', rarity = false }) {
        const userId = cookieService.getUserId();
        return {
          url: `/badges/collectionable-badges-from-creator/${creatorId}?page=${page}&author=${creatorId}&pageSize=${pageSize}&order=${order}${
            rarity ? `&rarity=${rarity}` : ''
          }`,
        };
      },
      transformResponse({ authBadges, badge_pages }: IGetBadgesResponse) {
        const badges = allBadgesAdapter.addMany(
          allBadgesAdapter.getInitialState(),
          authBadges?.map(formatBadgeResponse),
        );
        return {
          pages: badge_pages?.pages,
          badgeAmount: badge_pages?.total_values,
          allCreatorBadges: badges,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSamePage = currentArg.page === previousArg?.page;
        const isSameOrder = currentArg.order === previousArg?.order;
        const isSameRarity = currentArg.rarity === previousArg?.rarity;

        return !isSameCreator || !isSamePage || !isSameOrder || !isSameRarity;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}-${queryArgs.order}`;
      },
      merge(currentState, incomingState) {
        allBadgesAdapter.addMany(
          currentState.allCreatorBadges,
          allBadgesSelector.selectAll(incomingState.allCreatorBadges),
        );
      },
    }),
    checkFanAchievement: builder.query<ICheckFanAchievementResponse, ICheckFanAchievementParams>({
      query: ({ fan_id, creator_id, trigger_collectible_id }) => {
        return {
          url: `/public/v1/check_fan_achievement/${trigger_collectible_id}`,
          params: {
            fan_id,
            creator_id,
          },
        };
      },
    }),
    claimBadge: builder.mutation<any, IClaimPayload>({
      query(body) {
        return {
          url: '/badge-claim',
          method: 'POST',
          body,
        };
      },
    }),
    transferBadge: builder.mutation<any, ITransferPayload>({
      query(payload) {
        return {
          url: '/badge-transfer',
          method: 'POST',
          body: {
            ...payload,
            amount: 1,
          },
        };
      },
      invalidatesTags: [QUERIES.badges],
    }),
    getBadgeById: builder.query({
      query: (id) => `/badge/${id}?id=${id}`,
      transformResponse: formatBadgeResponse,
    }),
  }),
});

export const {
  useClaimBadgeMutation,
  useTransferBadgeMutation,
  useGetBadgesInfiniteQuery,
  useGetBadgesByAuthorQuery,
  useLazyGetBadgeByIdQuery,
  useCheckFanAchievementQuery,
} = badgesApi;
