import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  IFanConversationSet,
  IFanConversationQueryParams,
  IFanConversationResponse,
  INormalizedFanConversationResponse,
  IMarkConversation,
  IFanCommunityConversationQueryParams,
} from '@ui/components';
import { dataQuery } from './leaderboard-api';
import { QUERIES } from '@constants/query.constants';
import { creatorsApi } from './creators-api';

export const fanConversationsAdapter = createEntityAdapter({
  selectId: (item: IFanConversationSet) => item.conversation.id,
  sortComparer: (a, b) => {
    const lastMessageA = a.last_message || '';
    const lastMessageB = b.last_message || '';
    return lastMessageB.localeCompare(lastMessageA);
  },
});

export const fanConversationsSelector = fanConversationsAdapter.getSelectors();

export const fanConversationsApi = createApi({
  reducerPath: 'fanConversationsApi',
  baseQuery: dataQuery,
  tagTypes: [QUERIES.conversations, QUERIES.singleConversation, QUERIES.communityConversations],
  endpoints: (builder) => ({
    getFanConversations: builder.query<
      INormalizedFanConversationResponse,
      IFanConversationQueryParams
    >({
      query: ({ fan_id, creator_id, page, items_per_page }) => ({
        url: `/public/v1/forum/fan_conversations/${fan_id}`,
        params: { creator_id, page, items_per_page },
      }),
      transformResponse: ({ conversations, total_pages }: IFanConversationResponse) => {
        const filtered = conversations
          .filter((c) => Boolean(c.last_message)) // filter out empty conversations or conversation without message
          .map((conversationSet) => ({
            ...conversationSet,
            conversation: {
              ...conversationSet.conversation,
              main_thread: conversationSet.conversation.main_thread || conversationSet.thread_id,
            },
          }));

        const normalized = fanConversationsAdapter.addMany(
          fanConversationsAdapter.getInitialState(),
          filtered,
        );

        return {
          pages: total_pages,
          conversations: normalized,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creator_id === previousArg?.creator_id;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.creator_id}`;
      },
      providesTags: [QUERIES.conversations],
      merge: (currentState, incomingState) => {
        fanConversationsAdapter.addMany(
          currentState.conversations,
          fanConversationsSelector.selectAll(incomingState.conversations),
        );
      },
    }),
    getFanCommunityConversations: builder.query<
      INormalizedFanConversationResponse,
      IFanCommunityConversationQueryParams
    >({
      query: ({ fan_id, ...rest }) => ({
        url: `/public/v1/forum/fan_conversations/${fan_id}`,
        params: rest,
      }),
      transformResponse: ({ conversations, total_pages }: IFanConversationResponse) => {
        const filtered = conversations.map((conversationSet) => ({
          ...conversationSet,
          conversation: {
            ...conversationSet.conversation,
            main_thread:
              conversationSet.conversation.main_thread || conversationSet.conversation_thread,
          },
        }));

        const normalized = fanConversationsAdapter.addMany(
          fanConversationsAdapter.getInitialState(),
          filtered,
        );

        return {
          pages: total_pages,
          conversations: normalized,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creator_id === previousArg?.creator_id;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs?.creator_id}-${queryArgs?.conversation_type}`;
      },
      providesTags: [QUERIES.communityConversations],
      merge: (currentState, incomingState) => {
        fanConversationsAdapter.addMany(
          currentState.conversations,
          fanConversationsSelector.selectAll(incomingState.conversations),
        );
      },
    }),
    getFanConversationById: builder.query<IFanConversationSet, string>({
      query: (id) => ({
        url: `/public/v1/forum/fan_conversation/${id}`,
      }),
      transformResponse({ conversations, conversation_thread }) {
        return {
          conversation: {
            ...conversations,
            main_thread: conversations.main_thread || conversation_thread,
          },
        };
      },
      providesTags: (result, error, id) => [{ type: QUERIES.singleConversation, id }],
    }),
    markConversation: builder.mutation<{ success: boolean }, IMarkConversation>({
      query: ({ conversationId, fan_id, creator_id }) => ({
        url: `/public/v1/forum/mark_conversation/${conversationId}`,
        method: 'PUT',
        params: { fan_id, creator_id },
      }),
      async onQueryStarted({ creator_id, conversationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          fanConversationsApi.util.updateQueryData(
            'getFanConversations',
            { creator_id } as any,
            (draft) => {
              fanConversationsAdapter.updateOne(draft.conversations, {
                id: conversationId,
                changes: {
                  mark: true,
                },
              });
            },
          ),
        );
        try {
          dispatch(creatorsApi.util.invalidateTags([QUERIES.creators]));
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
      invalidatesTags: [QUERIES.conversations, QUERIES.singleConversation],
    }),
  }),
});

export const {
  useGetFanConversationByIdQuery,
  useGetFanConversationsQuery,
  useMarkConversationMutation,
  useGetFanCommunityConversationsQuery,
} = fanConversationsApi;
